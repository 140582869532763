import { useEffect, useState } from 'react'

export enum TYPES {
  SECOND = 1000,
  MINUTE = 60000,
}

type Props = {
  start: number
  type?: TYPES
  doneCallback?: () => void
  infinite?: boolean
}

const Countdown: React.FC<Props> = ({ start, type = TYPES.SECOND, doneCallback, infinite }) => {
  const [count, setCount] = useState<number>(start)

  useEffect(() => {
    const interval = setInterval(() => {
      setCount((prev) => {
        if (prev === 0) {
          if (doneCallback) {
            clearInterval(interval)
            doneCallback()
          }
          if (infinite) {
            return start
          }
          return prev
        }
        return prev - 1
      })
    }, type)
    return () => clearInterval(interval)
  }, [count, doneCallback, type, start, infinite])

  return (
    <span className="countdown">
      <span style={{ '--value': count } as React.CSSProperties} className="font-bold"></span>
    </span>
  )
}

export default Countdown
